.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }


.upload-page{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100vh;
}

.upload-page > form{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}


.upload-page > form > input {
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-family: 'VT323', monospace;
    color: rgb(70,252,62);
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(70,252,62);
}

.upload-page > form > select {
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-family: 'VT323', monospace;
    color: rgb(70,252,62);
    background: none;
    outline: none;
    margin-top: 30px;
    border: 1px solid rgb(70,252,62);
}

.upload-page > form > .file-select-input{
    height: 50px;
    font-size: 20px;
    font-family: 'VT323', monospace;
    color: rgb(70,252,62);
    background: none;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-top: 30px;
}


.upload-button{
    margin-top: 30px;
    height: 50px;
    width: 200px;
    color: black;
    /* background-color: rgb(70,252,62); */
    border: none;
    font-family: 'VT323', monospace;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.upload-button:hover{
    background-color: rgb(58, 214, 52);
}

.upload-button:active{
    background-color: rgb(58, 214, 52);
}


.btn-load{
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 5px solid grey;
    border-radius: 100%;
}

.btn-open{
	border-top: 5px solid transparent;
    animation: load-animate infinite linear 1s;
}


@keyframes load-animate {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); opacity: 0.35; }
    100% { transform: rotate(360deg); }
  }