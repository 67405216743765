.mobile-music-item-component{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: calc((100% / 2) - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 15px;

}

.mobile-music-item-album-cover{
    border-radius: 3px;
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-music-item-info-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

}

.mobile-music-item-info-container > h4{
    color: black;
    margin-top: 5px;
    margin-bottom: 0;

}

.mobile-music-item-info-container > p{
    color: #bec0c5;
    margin-top: 0;
    margin-bottom: 0;
    
}

.music-item-music-icon{
    color:black;
    font-size: 40px;
}

@media only screen and (max-width: 375px){
    .mobile-music-item-album-cover{
        height: 145px;
    }
}