.desktop-music-icon{
  cursor: pointer;
  width: calc((100%/4) - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.desktop-music-item-album-cover{
    border-radius: 3px;
    height: 115px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-music-icon:hover > .desktop-music-item-album-cover {
    filter: grayscale(100%);
    /* filter: contrast(200%); */
}

.desktop-music-item-music-icon{
    font-size: 30px;
    color: black;
}


.desktop-music-item-info-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    padding-left: 3px;
}

.desktop-music-item-info-container > h4{
    color: white;
    margin-top: 5px;
    margin-bottom: 0;
}

.desktop-music-item-info-container > p{
    color: #bec0c5;
    margin-top: 0;
    margin-bottom: 0;
}