.mobile-page-nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    /* margin-top: 10px; */
    /* padding-left: 7px; */
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

}


.mobile-page-nav > .row:nth-child(odd){
    flex-basis: 20%;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

}

.mobile-page-nav > .row:nth-child(odd) > .back-arrow{
    cursor: pointer;
    color: #fb294c;
    font-size: 22px;
}


.mobile-page-nav > .row:nth-child(odd) > p{
    cursor: pointer;
    color: #fb294c;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    padding-left: 5px;
}




.mobile-page-nav > .row:nth-child(even){
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}


.mobile-page-nav-name_hidden{
    opacity: 0 !important;
    transition: opacity 0.1s ease;

}
.mobile-page-nav-name{
    color: black;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
    opacity: 1;
    transition: opacity 0.1s ease;
}
