.main-content-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e;
}

.App-logo{
  max-height: 50px;
  width: auto;
}




/* FOLDER.JS CSS */
.folder-component{
  cursor: pointer;
  height: 80px;
  width: 80px;
  /* display: flex; */
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}


.folder-component-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid #1e1e1e;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}


.folder-component-text-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 4px;
  border-radius: 3px;
}

.folder-name-text{
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.folder-component:active > .folder-component-image-container{
  border-color:rgba(55, 59, 65,1);
  border-radius: 5px;
}

.folder-component:active > .folder-component-text-container{
  background: #0e5ccd;
}





/* MUSICITEM.JS */
.music-item-component{
  margin-bottom: 20px;
  cursor: pointer;
  width: calc((100%/4));
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}


.music-item-component-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid #403f3f;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}



.music-item-play-overlay-container {
  display: none;

}

.music-item-play-button{
  color: white;
  font-size: 22px;
}

.music-item-component:hover  .music-item-play-overlay-container{
  position: absolute;
  background-color: rgba(0, 0, 0, .3);
  max-height: 80px;
  min-height: 80px;
  max-width: 80px;
  min-width: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}




.music-item-component-image-container > img{
  max-height: 80px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

}


.music-item-component-text-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 4px;
  border-radius: 3px;
}

.music-item-name-text{
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.music-item-component:active > .folder-component-image-container{
  border-color:rgba(55, 59, 65,1);
  border-radius: 5px;
}

.music-item-component:active > .folder-component-text-container{
  background: #0e5ccd;
}









/* FOOTER */

.footer{
  height: 50px;
  width: 100%;
  background-color: white;
}




@media only screen and (max-width: 414px){
  .mac-window-component{
    height: 350px;
    width: 85%;
  }

  .window-view{
    height: unset;
    flex: 1;
  }


}

@media only screen and (max-width: 375px){
  .mac-window-component{
    align-self: flex-start;
    margin-top: 20px;
    height: 470px;
    width: 85%;
  }

}





/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
