.artwork-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 64px;
    max-height: 100%;
    width: 64px;
    padding: 8px 0;
    text-align: center;
    transition: all 0.35s ease;
}

.artworkContainer_show{
    height: 46vh;
    min-height: 8em;
    width: 100%;
}

.artwork-container_hidden{
    height: 64px;
}


.artwork{
    pointer-events: none;
    user-select: none;
    transition: all 0.35s ease; 
    /* border: 1px solid black; */
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.artwork_show{
    height: 90%;
    width: 60%;
}

.artwork_hidden{
    height: 50px;
    width: 50px;
}


.artwork-music-icon{
    color: black;
    transition: all 0.35s ease
}

.artwork-music-icon_show{
    font-size: 40px;
}

.artwork-music-icon_hidden{
    font-size: 16px;
}



@media only screen and (min-width: 600px){
    .artwork_show{
        height: 250px;
        width: 250px;
    }
}


@media only screen and (max-width: 414px){
    .artwork_show{
        height: 270px;
        width: 270px;
    }
}


@media only screen and (max-width: 375px){

    .artworkContainer_show{
        height: 260px;
    }

    .artwork-music-icon_show{
        font-size: 40px;
    }
    
    .artwork_show{
        height: 230px;
        width: 230px;
    }

}