.mobile-content-container{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-bottom: 120px;
    
}


.mobile-home-header-container{
    /* padding-left: 15px; */
}
.mobile-home-header-container > h1{
    font-family: 'SF Display Medium', sans-serif;
    margin-left: 17px;
    margin-bottom: 5px;
    font-size: 26px;
    letter-spacing: -1px;

}


.recently-added-text-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    
}

.recently-added-text-container > .recently-added-text{
    font-size: 20px;
    padding-left: 17px;
}

.mobile-music-items-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

}





/* .mobile-contact-page_hidden{

}

.mobile-contact-page{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: 

} */
