.react-player-hidden{
    display: none;
}
.inactive-icon-color{
    color: #8e8e8e;
}

.active-icon-color{
    color: black;
}

.play-pause-control{
    cursor: pointer;
    font-size: 22px;
}

.play-pause-control:hover{

}

.player-container{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-self: stretch;
    transition: all 0.7s ease;
    
}

.player-container_show{
    bottom: 0;
    height: 93%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-self: stretch;
    transition: all 0.7s ease;
}

.player-container_hide{
    bottom: 0;
    /* background-color: rgb(220, 219, 219); */
    background-color: rgb(232, 231, 231);

}




.player-music-album-cover-container{
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-music-album-cover-container > .player-music-album-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 5px;
    height: 50px;
    width: 50px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.player-music-album-cover-container_active {
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-music-album-cover-container_active > .player-music-album-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 5px;
    height: 300px;
    width: 300px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}


.music-player-music-icon{


}

/* name container */
.player-music-name-container{
    flex: 1; 
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.player-music-song-title{
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    font-size: 20px;
}

/* controls container */
.player-music-controls-container{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;

}





.player-name-container{
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 1px solid #eeee; */

}

.player-name-container > h4{
    color: white;
    font-weight: 300;
    font-size: 25px;
}

.player-buttons-container{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 1px solid #eeee; */
}


.player-seeker-container{
    flex: 1;
    align-self: stretch;
    /* border-right: 1px solid #eeee; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-seeker-container > input{
    width: 80%;
}

.player-share-container{
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: 15%;

}







@media only screen and (max-width: 414px){
    .player-share-container{
        flex-grow: 0; 
        flex-shrink: 0;
        flex-basis: 0;
    
    }

    .player-name-container{
        flex-grow: 0; 
        flex-shrink: 0;
        flex-basis: calc(100%/3);
    }

    .player-buttons-container{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100%/3);
        
    }

    .player-name-container > h4{
        font-size: 20px;
    }

}