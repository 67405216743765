.mobile-contact-page{

}


.contact-method-container{
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}


.contact-icon{
    font-size: 50px;
    color: black;
}

.contact-icon_mail{
    /* color: black; */
}

.contact-icon_twitter{
    /* color: #00aced; */
}

.contact-icon_insta{
    /* color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}