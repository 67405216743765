.mac-window-component{
    height: 400px;
    width: 700px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
  
  }
  
  .window-bar{
    height: 25px;
    background-color: #2e3136;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .window-controls-container{
    /* cursor: pointer; */
    width: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  
  .window-bar > .window-controls-container:nth-child(1){
    cursor: pointer;
  }
  
  
  .window-control{ 
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .window-control__close{
    background-color: rgb(255, 95, 87);
    border-color: rgb(226, 70, 63);
    margin-left: 5px;
  }
  
  .window-control__minimize{
    background-color: rgb(255,189,46);
    border-color: rgb(225,161,22);
  }
  
  .window-control__expand{
    background-color: rgb(40,201,64);
    border-color: rgb(18,172,40);
  }
  
  .window-title-container{
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .window-title-container > p{
    margin-top: 0;
    margin-bottom: 0;
    color: #999ba0;
  }
  
  .window-view{
      /* background-color: #24272d; */
    /* background-color: #181819; */
        /* background-color: #595959; */
    /* background-color: #403f3f;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: scroll; */

    height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;



  }


  .window-view-sidebar{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    background-color: #292929;
  }

  .window-sidebar-button{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;


  }

  .window-sidebar-button_inactive{

  }

  .window-sidebar-button_active{
    background-color: #0e5ccd;
  }


  .window-sidebar-button-text{
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;


  }
  .desktop-icon{
    color: white;
    margin-right: 5px;

  }


  /* MUSIC ITEM VIEW */
  .window-view-content{
    flex: 1;
    align-self: stretch;
    background-color: #403f3f;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
  }
  
  .window-view-content::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  .window-view-content::-webkit-scrollbar:vertical {
    width: 11px;
  }
  
  .window-view-content::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  
  .window-view-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }


  /* CONTACT VIEW */
.window-view-content_contact{
    flex: 1;
    align-self: stretch;
    background-color: #403f3f;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.desktop-contact-icon{
  color: white;
  font-size: 40px;
}

.desktop-contact-icon::vistied{
  color: white;
}