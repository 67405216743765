.mobile-header-link-bar{
    border-top: 1px solid #bec0c5;
}
.mobile-header-link{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    /* border-bottom: 1px solid #bec0c5; */
}

.mobile-header-link_blank-container{
    width: 15px;
    height: 100%;
}

.mobile-header-link_content-container{
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}


.mobile-header-link_text-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

}



.mobile-header-link-bar{
    border-top: 1px solid #bec0c5;
    width: 100%;
}



.mobile-header-link:active{
    background-color: #fb294c;
}

.mobile-header-link:active > .mobile-header-link_content-container > .mobile-header-link-bar{
    border-color: #fb294c;
}


.mobile-header-link:active > .mobile-header-link_content-container > .mobile-header-link_text-container > .mobile-header-link_name-container > p{
    color: white
}


.mobile-header-link:hover{
    background-color: #fb294c;
}

.mobile-header-link:hover > .mobile-header-link_content-container > .mobile-header-link-bar{
    border-color: #fb294c;
}

.mobile-header-link:hover > .mobile-header-link_content-container > .mobile-header-link_text-container > .mobile-header-link_name-container > p{
    color: white
}



.mobile-header-link_name-container{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.mobile-header-link_name-container > p{
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fb294c;
    font-size: 20px;
    padding-left: 5px;
}

.mobile-header-link_arrow-container{
    flex: 1 1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

}

.mobile-header-link-icon{
    color: #e0e1e6;

}