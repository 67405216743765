
.bar-info-container{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    transition: all 0.1s ease;
}

.bar-info-container_show{
    /* pointer-events: initial; */
    opacity: 0;
}

.bar-info-container_hidden{
    /* pointer-events: none; */
    opacity: 1;
}

.song-title{
    margin: 0;
    font-weight: normal;
    color: #646464;
    font-size: 13px;
}

.button-container{
    height: 64px;
    width: 64px;
    /* pointer-events: all; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 25px; */
}

.bar-info-control-icon{
    font-size: 18px;
    cursor: pointer;

}


.svg-icon{
    height: 24px;
    width: 24px;
    margin: 0 8px; 
}


