.mobile-touch-control{
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 64px;
    /* background-color: black; */
    z-index: 5;

}

.mobile-touch-control_hidden{
    display: none;
}

.mobile-touch-control_show{
    display: flex;
}