.admin-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: black;

}

.admin-page-button{
    margin-top: 30px;
    height: 50px;
    width: 150px;
    color: black;
    /* background-color: rgb(70,252,62); */
    border: none;
    font-family: 'VT323', monospace;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
}

.white-button{background-color: white}
.white-button:hover{background-color: #afafaf}
.white-button:active{background-color: #afafaf}

.red-button{background-color: #fc3e46;}
.red-button:hover{background-color: #b32b31;}
.red-button:active{background-color: #b32b31;}

.blue-button{background-color: #3ea5fc;}
.blue-button:hover{background-color: #368bd4;}
.blue-button:active{background-color: #368bd4;}

.green-button{background-color: rgb(70,252,62);}
.green-button:hover {background-color: rgb(58, 214, 52);}
.green-button:active{background-color: rgb(58, 214, 52);;}