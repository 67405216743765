.full-screen-controls{
    transition: all 0.35s ease;
} 

.full-screen-controls_show{
    opacity: 1;
}

.full-screen-controls__hidden{
    opacity: 0;
}


/* ------- NAME CONTAINER ------- */
.full-screen-name-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

}

.full-screen-name-container_name{
    margin-top: 0;
    margin-bottom: 0;

}

/* ------- SEEKER CONTAINER ------- */
.full-screen-seeker-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}


.time-range-slider{
    width: 80%;
    /* -webkit-appearance: none; */
}

/* .time-range-slider::-webkit-slider-runnable-track{
    width: 80%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

.time-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: rgb(117, 119, 120);
    margin-top: -5px;
}


.time-range-slider:focus {
    outline: none;
}

.time-range-slider:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

.time-range-slider:active::-webkit-slider-thumb{
    background: rgb(246, 42, 84);
    height: 28px;
    width: 28px;
    margin-top: -11px;
} */


/* ------- TIMING CONTAINER ------- */
.full-screen-timing-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-screen-timing-container > .container{
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}





/* ------- BUTTONS CONTAINER ------- */
.full-screen-buttons-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
}


.full-screen-control-icon{
    cursor: pointer;
    font-size: 32px;
    color: black;
}


/* ------- SHARE CONTAINER ------- */

.full-screen-share-container{
    /* cursor: pointer; */
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

}

.full-screen-share-container > span{
    font-size: 20px;

}

.full-screen-share-icon-container > span{
    transform: rotate(90deg);
    padding-left: 7px;
    
}

.full-screen-share-container > p{
    color: rgb(246, 42, 84);
    
}


@media only screen and (min-width: 600px){
    .time-range-slider{
        width: 270px;
    }
    
    .full-screen-timing-container > .container{
        width: 280px;
    }
}
