.react-player-hidden{
    display: none !important;
}

.mobile-player-container{
    /* position: relative; */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    
    transition: all 0.35s ease;
}

.mobile-player-container_show{
    height: 100%;
    background-color: #fff;
    height: 93%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);

}

.mobile-player-container_hidden{
    height: 64px;
    background-color: rgba(239, 239, 239, 0.97);
}

