.mobile-page-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 25px;
}

.mobile-page-header > h1{
    font-family: 'SF Display Medium', sans-serif;
    margin-left: 17px;
    margin-bottom: 5px;
    font-size: 26px;
}