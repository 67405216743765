.close-control-container{
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.35s ease;
    cursor: pointer;
}

.close-control-container_hidden{
    opacity: 0;
    height: 0;
}

.close-control-container_show{
    opacity: 1;
    height: 48px
}

.close-control-icon{
    color: #b7b7b7;
    font-size: 30px;
    
}