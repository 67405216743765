.song-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.song-cover{
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* background-color: black; */
}

.song-cover_overlay{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.7);

}

.song-cover-play-button{
    font-size: 80px;
}

.song-title{
    font-size: 40px;
}

.song-genre{

}

.song-play-button{

}

.song-music-icon{
    font-size: 30px
}