.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }


.login-page{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100vh;
}


.login-page > form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}


.login-page > form > input {
    height: 50px;
    font-size: 30px;
    font-family: 'VT323', monospace;
    color: rgb(70,252,62);
    background: none;
    border: none;
    outline: none;
    /* margin-left: 10px; */
    border-bottom: 1px solid rgb(70,252,62);
}


.login-button{
    margin-top: 30px;
    height: 50px;
    width: 100%;
    color: black;
    background-color: rgb(70,252,62);
    border: none;
    font-family: 'VT323', monospace;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}


@media only screen and (max-width: 414px){
    .login-label-container > h4 {
        font-family: 'VT323', monospace;
        color: rgb(70,252,62);
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 0;
        width: 100%;
        text-align: right;
    }
        

        .login-input-container > input {
            height: 50px;
            font-size: 20px;
            margin-top: 30px;
            font-family: 'VT323', monospace;
            color: rgb(70,252,62);
            background: none;
            border: none;
            outline: none;
            margin-left: 10px;
            border-bottom: 1px solid rgb(70,252,62);
            margin-right: 45px;
        }


}